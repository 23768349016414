import axios from 'axios'
import { showToast } from 'vant'

let isAllData = false
const service = axios.create({
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded'
  },
  responseType: 'json',
  // withCredentials: true,
  timeout: 30 * 1000
})

// Request interceptors
service.interceptors.request.use(
  (config) => {
    // 是否获取返回全部数据
    if (Reflect.has(config.params, 'isAllData')) {
      isAllData = config.params.isAllData
      Reflect.deleteProperty(config.params, 'isAllData')
    }
    return {
      ...config,
      url: (process.env.VUE_APP_NODE_ENV === 'dev' ? '/api' : process.env.VUE_APP_BASE_API) + config.url
    }
  },
  (error) => {
    return Promise.reject(error)
  }
)

// Response interceptors
service.interceptors.response.use(
  (response) => {
    const responseData = response.data
    const responseDataData = responseData.data
    if (responseData.success === 1) {
      return Promise.resolve(isAllData ? responseData : responseDataData)
    } else if (isAllData) {
      return Promise.resolve(responseData)
    } else {
      return Promise.reject(responseData)
    }
  },
  (error) => {
    if (error && error.data && error.data.msg) {
      showToast(error.data.msg)
    } else {
      // Toast.show({ content: error || '接口请求超时' })
      showToast(error || '接口请求超时')
    }
    return Promise.reject(error)
  }
)

export default service
