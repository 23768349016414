import request from '../request'

export const incomeExplain: any = {
  // 收益规则
  incomeRule(params: any) {
    return request({
      url: '?_c=Jishihtml&_a=incomeRule',
      params,
      method: 'get'
    })
  },
  // 晋升规则
  promotionRule(params: any) {
    return request({
      url: '?_c=Jishihtml&_a=promotionRule',
      params,
      method: 'get'
    })
  }
}