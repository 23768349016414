import "core-js/modules/es.array.push.js";
import { defineComponent, reactive, toRefs, onMounted, ref } from 'vue';
import { useRoute } from 'vue-router';
import { incomeExplain } from '@/api';
import { showToast } from 'vant';
// 图表
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TitleComponent, TooltipComponent, LegendComponent, GridComponent, DataZoomComponent,
// 数据集组件
DatasetComponent,
// 内置数据转换器组件 (filter, sort)
TransformComponent } from 'echarts/components';
import { LabelLayout, UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';
// 注册必须的组件
echarts.use([TitleComponent, TooltipComponent, LegendComponent, GridComponent, DataZoomComponent, DatasetComponent, TransformComponent, LineChart, LabelLayout, UniversalTransition, CanvasRenderer]);
export default defineComponent({
  setup() {
    const route = useRoute();
    const incomeChartsRef = ref(null);
    const dataMap = reactive({
      route: route,
      id: '',
      month: '',
      incomeTabActived: '0',
      incomeTabs: [{
        label: '收益规则',
        value: '0'
      }, {
        label: '晋升规则',
        value: '1'
      }],
      incomeDataLoading: true,
      incomeData: null
    });
    const incomeTabsChange = item => {
      dataMap.incomeTabActived = item.value;
      getRuleData();
    };
    const getRuleData = async () => {
      const {
        incomeTabActived,
        id,
        month
      } = dataMap;
      try {
        dataMap.incomeDataLoading = true;
        const apiKey = incomeTabActived === '0' ? 'incomeRule' : 'promotionRule';
        const res = await incomeExplain[apiKey]({
          id,
          month
        });
        // 收益级别并且不是创客技师添加综合得分
        if (incomeTabActived === '0' && res.type !== '2') {
          // 计算上月综合得分
          res.detail.push({
            rank_title: '综合得分',
            rank_val: '-',
            single: '-',
            percent: '-',
            score: res.score
          });
          if (res.next_commission_radio > 0) {
            // 计算本月综合得分
            res.next_detail.push({
              rank_title: '综合得分',
              rank_val: '-',
              single: '-',
              percent: '-',
              score: res.next_score
            });
          }
        }
        dataMap.incomeData = res;
        // 初始化图表
        if (incomeTabActived === '0' && res.next_commission_radio > 0) {
          setTimeout(() => {
            const myChart = echarts.init(incomeChartsRef.value);
            let xAxisData = [];
            let seriesData = [];
            res.synthesis_score.forEach(item => {
              xAxisData.push(item.date + '日');
              seriesData.push(item.score);
            });
            let options = {
              xAxis: {
                type: 'category',
                data: xAxisData
              },
              yAxis: {
                type: 'value'
              },
              legend: {
                top: 'bottom'
              },
              dataZoom: [{
                type: 'inside',
                realtime: true,
                zoomLock: true,
                startValue: 0,
                endValue: 7
              }],
              series: [{
                name: '每日综合得分图表',
                label: {
                  show: true,
                  position: 'top'
                },
                itemStyle: {
                  color: '#4dbf8b',
                  borderWidth: 2
                },
                lineStyle: {
                  color: '#4dbf8b',
                  width: 2 // 线的宽度
                },
                type: 'line',
                smooth: true,
                data: seriesData
              }]
            };
            myChart.setOption(options);
            // 监听 legendselectchanged 事件
            myChart.on('legendselectchanged', event => {
              myChart.setOption({
                legend: {
                  selected: {
                    [event.name]: true
                  }
                }
              });
            });
          });
        }
      } finally {
        dataMap.incomeDataLoading = false;
      }
    };
    onMounted(() => {
      const id = route.query.id;
      const month = route.query.month;
      if (id) {
        dataMap.id = id;
      }
      if (month) {
        dataMap.month = month;
      }
      if (!id || !month) {
        showToast('缺少关键业务参数');
        return false;
      }
      getRuleData();
    });
    return {
      ...toRefs(dataMap),
      incomeChartsRef,
      incomeTabsChange,
      getRuleData
    };
  }
});